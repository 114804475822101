.-_VRTG_button {
  color: #fff;
  cursor: pointer;
  outline-offset: 4px;
  background: linear-gradient(to top, #2d2673, #872db4);
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 1.25rem;
  font-weight: 600;
}

.d7rB_G_wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 64px 32px;
}

.d7rB_G_wrapper header {
  align-items: flex-end;
  min-height: 300px;
  margin-bottom: 64px;
  display: flex;
  position: relative;
}

.d7rB_G_wrapper header h1 {
  color: #fff;
  text-shadow: 0 0 10px #1e183980, 0 0 20px #1e183980, 0 0 40px #1e183980;
  padding-bottom: 32px;
  font-size: 4.25rem;
  line-height: 1.1;
  position: relative;
}

.d7rB_G_wrapper header img {
  width: 250px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.d7rB_G_controlsWrapper {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  outline-offset: 4px;
  color: #000;
  background: #fff;
  border-radius: 4px;
  outline: 2px dashed #b1a3f599;
  margin-top: 32px;
  padding: 16px;
}

.d7rB_G_row {
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  min-height: 3rem;
  display: flex;
}

.d7rB_G_row:not(:last-of-type), .d7rB_G_titleMessageWrapper {
  border-bottom: 1px dotted #b1a3f580;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.d7rB_G_titleMessageRow {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.d7rB_G_titleMessageRow:not(:last-of-type) {
  margin-bottom: 16px;
}

.d7rB_G_label {
  text-align: right;
  flex-basis: 160px;
  font-weight: 700;
}

.d7rB_G_inputWrapper {
  flex: 1;
}

.d7rB_G_radioWrapper {
  flex-wrap: wrap;
  gap: 4px 16px;
  display: flex;
}

.d7rB_G_radioWrapper label {
  align-items: center;
  gap: 8px;
  width: 100px;
  display: flex;
}

.d7rB_G_messageInput {
  width: 100%;
  height: 4rem;
  display: block;
}

._iOXHW_showWrapper {
  font-size: .5rem;
}

._iOXHW_wrapper {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.GFhwnW_toast {
  color: #000;
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  box-shadow: var(--shadow-elevation-medium);
  background: #fff;
  border-radius: 16px;
  align-items: center;
  gap: 16px;
  width: 350px;
  max-width: 100%;
  display: flex;
  position: relative;
}

.GFhwnW_content {
  flex: 1;
  padding: 12px 0;
  font-weight: 600;
}

.GFhwnW_iconContainer {
  flex-shrink: 0;
  padding: 16px 0 16px 16px;
}

.GFhwnW_iconContainer svg {
  display: block;
}

.GFhwnW_closeButton {
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 16px;
}

.GFhwnW_notice {
  background: var(--color-notice-bg);
}

.GFhwnW_notice .GFhwnW_iconContainer {
  color: var(--color-notice);
}

.GFhwnW_warning {
  background: var(--color-warning-bg);
}

.GFhwnW_warning .GFhwnW_iconContainer {
  color: var(--color-warning);
}

.GFhwnW_success {
  background: var(--color-success-bg);
}

.GFhwnW_success .GFhwnW_iconContainer {
  color: var(--color-success);
}

.GFhwnW_error {
  background: var(--color-error-bg);
}

.GFhwnW_error .GFhwnW_iconContainer {
  color: var(--color-error);
}

@keyframes _YfUPa_toast {
  from {
    transform: translateX(calc(100% + 64px));
  }
}

._YfUPa_wrapper {
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  list-style-type: none;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

._YfUPa_toastWrapper {
  will-change: transform;
  animation: .8s cubic-bezier(0, .46, 0, 1.04) both _YfUPa_toast;
}

.BOBUQq_wrapper {
  text-align: center;
  max-width: 500px;
  margin: 32px auto;
  padding: 32px 16px 64px;
}

.BOBUQq_wrapper p {
  color: #c9d1f7;
  font-size: .875rem;
}

.BOBUQq_wrapper p a {
  color: #e7ebfe;
  font-weight: 700;
  text-decoration: none;
}

.BOBUQq_wrapper p a:hover {
  text-decoration: underline;
}

.BOBUQq_wrapper p:not(:last-of-type) {
  margin-bottom: 1em;
}
/*# sourceMappingURL=index.fcd2b4b7.css.map */
